/***********************************************************
 HTML Helpers
 --------------------------
 Contains helper functions to operate on the HTML DOM.
 ************************************************************/

import {isString} from "./string-helpers.js";

/**
 * Removes all html content of the specified string.
 *
 * @param allowedTags The tags that shall not be removed.
 */
export function stripHtml(html: string, allowedTags = []): string {
    if (allowedTags && allowedTags.length) {
        const regexTags = allowedTags.map(tag => `${tag}|\/${tag}`).join('|');
        const regex = new RegExp(`<(?!${regexTags}).*?>`, 'ig');

        return html.replace(regex, '')

    }
    return html.replace(/(<([^>]+)>)/ig, '')
}

/**
 * Scrolls the window to the specified position index with a smooth animation.
 *
 * @param position
 * @param scrollElement
 * @param horizontal
 */
export function scrollToPosition(position: number, scrollElement: HTMLElement|Window|null = null, horizontal = false) {
    // If no position is specified, we just scroll to the top
    if (!position) {
        position = 0
    }

    scrollElement = scrollElement ? scrollElement : window;

    if (typeof scrollElement !== 'undefined') {
        if (scrollElement.scrollTo) {
            scrollElement.scrollTo({
                top: horizontal ? 0 : position,
                left: horizontal ? position : 0,
                behavior: 'smooth'
            });
        } else {
            // IE 11
            if (horizontal) {
              // @ts-ignore
                scrollElement.scrollLeft = position;
            } else {
              // @ts-ignore
                scrollElement.scrollTop = position;
            }
        }
    }
}


/**
 * Gets the y position of the element with the given selector.
 *
 * @param selector
 * @return int|null
 */
// @ts-ignore
export function getElementPositionFromTop(selector) {
    if (typeof window !== 'undefined') {

        let element = selector;
        if (isString(selector) && selector.startsWith('#')) {
            element = document.getElementById(selector.substr(1))
        }

        if (!element) {
            return null;
        }

        return element.getBoundingClientRect().top;
    }

    return null;
}

/**
 * Scrolls the window to the specified element selector with a smooth animation.
 *
 * @param selector
 * @param offsetScrollingDown The offset to add to the calculated position when scrolling down to the selector; defaults to the header height + some spacing
 * @param offsetScrollingUp The offset to add to the calculated position when scrolling up to the selector; defaults to the header height + some spacing
 * @return int the scrolledPosition
 */
// @ts-ignore
export function scrollToSelector(selector, offsetScrollingDown = -80, offsetScrollingUp = null) {
    if (typeof window !== 'undefined') {

        let elementY = getElementPositionFromTop(selector);
        elementY = elementY === null ? 0 : elementY;
        let offset = offsetScrollingDown;
        if (elementY < 0) {
            offset = offsetScrollingUp === null ? offsetScrollingDown : offsetScrollingUp;
        }

        const body = document.body;
        const docEl = document.documentElement;
        const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
        const clientTop = docEl.clientTop || body.clientTop || 0;
        const top = elementY + scrollTop - clientTop;

        scrollToPosition(top + offset)

        return top + offsetScrollingDown;
    }
}