<template>
  <NuxtLayout>
    <v-app>
      <v-main class="pt-0">
        <app-header/>
        <div>
          <cover-image src="/images/covers/error-cover-desktop.jpg"
                       phone-src="/images/covers/error-cover-phone.jpg"
                       tablet-src="/images/covers/error-cover-tablet.jpg" color="#d80228" secondary-color="#21211e"
                       :title="errorTitle"
                       :description="errorMessage"
                       action-text="Zur Startseite"
                       action-path="/"
                       left
                       main full-height>

            <app-footer dark absolute hide-social/>

          </cover-image>

        </div>
      </v-main>
    </v-app>
  </NuxtLayout>
</template>

<script setup lang="ts">
const props = defineProps({
  error: Object
})

const statusCode = (props.error && props.error.statusCode) || 500;
const errorTitle = statusCode === 404 ? '404' : 'Ups!';
const errorMessage = statusCode === 404 ? 'Diese Seite existiert leider nicht.' : 'Ein unbekannter Fehler ist aufgetreten. Bitte versuche es später noch einmal.'

// This will be reactive even you change title/description above
useHead({
  htmlAttrs: {
    lang: 'de'
  },

  title: `${errorTitle} | Leila Krejci | Musiker • Model • Content Creator`,

  meta: [
    {charset: 'utf-8'},
    {name: 'viewport', content: 'width=device-width, initial-scale=1.0, minimum-scale=1.0 maximum-scale=1.0'},
    {name: 'author', content: 'Leila Krejci'},
    // Fix for IE 11 rendering issues
    {'http-equiv': 'X-UA-Compatible', content: 'IE=edge'},

      {name: 'msapplication-TileColor', content: '#da532c'},
      {name: 'theme-color', content: '#ffffff'},
  ],
    link: [

        // Custom font
        {rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Montserrat:400,500,700'},

        // Favicon
        {rel: 'apple-touch-icon', type: 'image/png', sizes: '180x180', href: '/images/favicons/apple-touch-icon.png'},
        {rel: 'icon', type: 'image/png', sizes: '32x32', href: '/images/favicons/favicon-32x32.png'},
        {rel: 'icon', type: 'image/png', sizes: '16x16', href: '/images/favicons/favicon-16x16.png'},
        {rel: 'manifest', href: '/images/favicons/site.webmanifest'},
        {rel: 'mask-icon', type: 'image/png', color: '#5bbad5', href: '/images/favicons/safari-pinned-tab.svg'},
        {rel: 'shortcut icon', type: 'image/x-icon', href: '/images/favicons/favicon.ico'},
    ]
})
</script>