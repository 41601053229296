import { default as indexzAjXKtfMiBMeta } from "/home/runner/work/leilakrejci/leilakrejci/src/pages/index.vue?macro=true";
import { default as _91shoot_93gE1V33PFWiMeta } from "/home/runner/work/leilakrejci/leilakrejci/src/pages/photo-shoots/[shoot].vue?macro=true";
import { default as indexDJDjhY4BVNMeta } from "/home/runner/work/leilakrejci/leilakrejci/src/pages/photo-shoots/index.vue?macro=true";
import { default as _91shoot_93H5c3BPAPfwMeta } from "/home/runner/work/leilakrejci/leilakrejci/src/pages/video-shoots/[shoot].vue?macro=true";
import { default as index1RFOy618hhMeta } from "/home/runner/work/leilakrejci/leilakrejci/src/pages/video-shoots/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/leilakrejci/leilakrejci/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "photo-shoots-shoot",
    path: "/photo-shoots/:shoot()",
    component: () => import("/home/runner/work/leilakrejci/leilakrejci/src/pages/photo-shoots/[shoot].vue").then(m => m.default || m)
  },
  {
    name: "photo-shoots",
    path: "/photo-shoots",
    component: () => import("/home/runner/work/leilakrejci/leilakrejci/src/pages/photo-shoots/index.vue").then(m => m.default || m)
  },
  {
    name: "video-shoots-shoot",
    path: "/video-shoots/:shoot()",
    component: () => import("/home/runner/work/leilakrejci/leilakrejci/src/pages/video-shoots/[shoot].vue").then(m => m.default || m)
  },
  {
    name: "video-shoots",
    path: "/video-shoots",
    component: () => import("/home/runner/work/leilakrejci/leilakrejci/src/pages/video-shoots/index.vue").then(m => m.default || m)
  }
]