import type {DirectiveBinding} from "@vue/runtime-core";

function mounted(el: HTMLElement, binding: DirectiveBinding) {
    const { self = false } = binding.modifiers || {}
    const value = binding.value

    const enabled = typeof value === 'object' && value.enabled !== undefined ? value.enabled : true;
    if (!enabled) {
        return
    }

    const options = (typeof value === 'object' && value.options) || { passive: true }
    const handler = typeof value === 'function' || 'handleEvent' in value ? value : value.handler

    const target = self
        ? el
        : binding.arg
            ? document.querySelector(binding.arg)
            : window

    if (!target) return

    target.addEventListener('scroll', handler, options)

    // @ts-ignore
    el._onScroll = {
        handler,
        options,
        // Don't reference self
        target: self ? undefined : target,
    }
}

function unmounted(el: HTMLElement) {
    // @ts-ignore
    if (!el._onScroll) return

    // @ts-ignore
    const { handler, options, target = el } = el._onScroll

    target.removeEventListener('scroll', handler, options)

    // @ts-ignore
    delete el._onScroll
}

export const Scroll = {
    mounted,
    unmounted,
};

export default Scroll