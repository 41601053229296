<template>
    <v-app-bar :color="highlightAppBar ? 'rgba(0,0,0,0.3)' : 'transparent'"
               flat
               theme="dark"
               v-scroll="handleScroll">
        <v-container>
            <div class="d-flex flex-row align-center">
                <v-toolbar-title>
                    <nuxt-link :to="{name: 'index'}" class="text-white text-uppercase">Leila Krejci</nuxt-link>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <menu-btn class="d-md-none" v-model="showDrawer"/>

                <v-btn v-for="(item, itemKey) in navItems" :variant="route.fullPath === item.to ? 'text' : 'plain'" color="white" :key="itemKey" :to="item.to"
                       class="ml-4 d-none d-md-flex">
                    <span class="l-focus-text">{{ item.title }}</span>
                </v-btn>
            </div>
        </v-container>
    </v-app-bar>

    <client-only>
        <v-navigation-drawer
            v-model="showDrawer"
            image="/images/covers/laugh-cover-phone.jpg"
            theme="dark"
            class="rounded-t-xl overflow-hidden d-md-none"
            location="bottom"
            width="300"
            floating
            temporary>
            <v-list nav>
                <v-list-item to="/" variant="plain">
                    <v-list-item-title class="font-weight-bold text-uppercase">Leila Krejci</v-list-item-title>
                </v-list-item>
                <v-list-item v-for="(item, itemKey) in navItems" :key="itemKey" :to="item.to" :variant="route.fullPath === item.to ? 'text' : 'plain'">
                    <v-list-item-title><span class="l-focus-text text-uppercase">{{ item.title }} Leila</span></v-list-item-title>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </client-only>
</template>

<script setup lang="ts">

const route = useRoute();

const showDrawer = ref(false);
const highlightAppBar = ref(false);

const navItems = [
    {title: 'Learn about', to: '/#learn-about-leila'},
    {title: 'Look at', to: '/#look-at-leila'},
    {title: 'Listen to', to: '/#listen-to-leila'},
    {title: 'Link with', to: '/#link-with-leila'},
];

function handleScroll() {
    highlightAppBar.value = window.scrollY > 72
}

</script>

<style lang="scss">
.v-app-bar {
    transition: background-color 0.3s ease-in-out;
}
</style>