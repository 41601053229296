import {defineNuxtRouteMiddleware, navigateTo, useRuntimeConfig} from '#app'

/**
 * Middleware to redirect requested paths to their configured targets.
 */
export default defineNuxtRouteMiddleware( async (to) => {
    const config = useRuntimeConfig();
    if (!config.redirect) {
        return;
    }

    const fallbackStatusCode = config.redirect.statusCode ?? 301;
    const rules = config.redirect.rules;
    const pagePath = to.fullPath;

    const foundRule = rules.find(rule => (new RegExp(rule.from)).test(pagePath));
    if (!foundRule) {
        return;
    }

    const toPagePath = pagePath.replace((new RegExp(foundRule.from)), foundRule.to);

    return navigateTo(toPagePath, {
        redirectCode: foundRule.statusCode || fallbackStatusCode,
        external: true
    })
});
