<template>
    <v-footer :theme="props.dark ? 'dark' : 'light'" :class="{'v-footer--absolute': props.absolute}" color="transparent" v-scroll="refreshScrollTargets">
        <v-container class="py-0">
            <v-card flat tile :dark="props.dark" class="text-center" width="100%" color="transparent">
                <v-card-text v-if="!props.hideSocial" class="pb-0">
                    <v-btn v-for="(social, socialKey) in socialLinks" :key="socialKey" class="mx-2" variant="text" :icon="social.icon"
                           :href="social.url" rel="noopener" target="_blank" :title="social.title"
                           :color="props.dark ? 'white' : null"
                           size="large"/>
                </v-card-text>

                <v-card-text class="v-footer__copyright" :class="{'v-footer__copyright-dark': props.dark, 'pt-4': !props.hideSocial}">
                    <span>© {{ new Date().getFullYear() }} — <nuxt-link to="/"><strong>Leila Krejci</strong></nuxt-link></span>

                    <v-btn v-if="showScrollToTop" class="v-footer__to-top" variant="text" :color="props.dark ? 'white' : null" @click="scrollToPosition(0)" :icon="mdiArrowUp"/>
                </v-card-text>
            </v-card>
        </v-container>
    </v-footer>
</template>

<script setup lang="ts">
import socialLinks from "../config/social";
import {scrollToPosition} from "../helpers/html-helpers";
import {mdiArrowUp} from "@mdi/js";

const props = defineProps<{
    dark?: boolean,
    absolute?: boolean,
    hideSocial?: boolean
}>();

const showScrollToTop = ref(false);

function refreshScrollTargets() {
  showScrollToTop.value = typeof window !== 'undefined' && window.scrollY > 200;
}
</script>
<style lang="scss">
.v-footer__copyright {
    padding-bottom: 0;
    padding-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.6);

    a {
        color: rgba(0, 0, 0, 0.6) !important;
    }

    &.v-footer__copyright-dark {
        color: rgba(255, 255, 255, 0.7);

        a {
            color: rgba(255, 255, 255, 0.7) !important;
        }
    }
}

.v-footer--absolute {
    position: absolute;
    width: 100%;
    bottom: 0;
}

.v-footer__to-top {
    position: absolute;
    right: 0;
}
</style>