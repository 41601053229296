import {ref, watch} from "vue";

export type ToggleableComponentProps = {
    // Whether to display the component
    modelValue?: boolean
}

export const ToggleableComponentEmits = ['update:modelValue'];

export function useToggleableComponent(props: ToggleableComponentProps, emit: (event: string, ...args: any[]) => void) {
    const isActive = ref(props.modelValue);

    watch(() => props.modelValue, (newValue: boolean) => {
        isActive.value = newValue;
    });

    watch(isActive, (newValue) => {
        if (!!newValue !== props.modelValue) {
            emit('update:modelValue', newValue);
        }
    });

    return {
        isActive
    }
}