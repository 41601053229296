<template>
    <div :id="props.id" :style="{borderTop: props.main ? undefined : `2px solid ${secondaryColor ?? color}`}" class="cover-image">
        <v-img v-if="youtubeId" ref="imageComponent"
               :height="fullHeight ? '100vh' : '75vh'"
               cover
               :src="`https://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg`"/>
        <v-img v-else-if="src" ref="imageComponent"
               :height="fullHeight ? '100vh' : '75vh'"
               cover
               :src="src!"
               :srcset="srcset"/>
        <div v-else-if="backgroundColor" :style="{backgroundColor: props.backgroundColor, height: props.fullHeight ? '100vh' : '75vh'}"></div>
        <v-container class="cover-image__container fill-height" :class="{'justify-center align-center': main && !left}">
            <div class="cover-image__content float pa-24 pa-md-48 rounded-lg" :class="{'d-flex flex-column justify-center align-center': main && !left}" :style="{maxWidth: `${props.contentMaxWidth}px`, backgroundColor: 'rgba(0,0,0, 0.3)'}">
                <p v-if="subtitle" class="text-white cover-image__subtitle-top mb-4" :class="{'text-center': main && !left}">{{ subtitle }}</p>
                <div class="cover-image__title-wrapper">
                    <component :is="titleTag" class="cover-image__title text-white text-uppercase" :style="{borderColor: props.secondaryColor ?? props.color}">{{ title }}<span :style="{color: props.color}" class="ml-4">.</span></component>
                </div>
                <p v-if="description" class="text-white cover-image__subtitle-bottom" :class="{'text-center': props.main && !props.left}" v-html="description"/>

                <slot name="description"/>

                <wrapper v-if="actionText || $slots.buttons" grow :center="props.main && !props.left" :left="props.left || !props.main" break-column="xs" class="mt-32">
                    <slot name="buttons"/>
                    <v-btn v-if="actionText" :to="actionPath" :color="color" size="x-large" :disabled="props.disabled" :append-icon="props.disabled ? undefined : (actionPath?.startsWith('#') ? icons.arrowDown : icons.arrowRight)">
                        {{ actionText }}
                    </v-btn>
                </wrapper>

                <slot name="actions"/>

            </div>
            <slot/>
        </v-container>
        <div v-if="props.nextSectionId" class="float-fast cover-image__bottom-arrow">
            <v-btn variant="text" large :to="`#${props.nextSectionId}`" :icon="icons.arrowDown" color="white"/>
        </div>
    </div>
</template>

<script setup lang="ts">

import {mdiArrowDown, mdiArrowRight} from "@mdi/js";

const props = withDefaults(defineProps<{
  id?: string,
  youtubeId?: string|null,
  src?: string|null,
  tabletSrc?: string|null,
  phoneSrc?: string|null,
  backgroundColor?: string|null,
  color: string|null,
  secondaryColor?: string|null,
  title: string,
  subtitle?: string,
  description?: string,

  // Whether the component is used as first and main cover image of the page
  main?: boolean,

  // Whether the text content shall be aligned to the left
  left?: boolean,

  // Whether the component shall be stretched to the screen's full height
  fullHeight?: boolean,
  actionPath?: string,
  actionText?: string,
  contentMaxWidth?: number | string,
  disabled?: boolean,

  nextSectionId?: string,
}>(), {
  contentMaxWidth: 640,
});

const route = useRoute();

const icons = {
    arrowDown: mdiArrowDown,
    arrowRight: mdiArrowRight
}

const imageComponent = ref(null);

const titleTag = computed(() => {
  return props.main ? 'h1' : 'h2'
});

const srcset = computed(() => {
  return [
    props.phoneSrc ? `${props.phoneSrc} 1125w` : null,
    props.tabletSrc ? `${props.tabletSrc} 1536w` : null,
    props.src ? `${props.src} 1920w` : null,
  ].filter(Boolean).join(', ')
});

onMounted(() => {
  if (imageComponent.value?.image) {
    import('simple-parallax-js').then(module => {
      const simpleParallax = module.default;
      new simpleParallax(imageComponent.value.image, {
        scale: 1.2,
        orientation: 'down',
      })
    })
  }
});
</script>
<style lang="scss">
.cover-image {
    position: relative;

    .cover-image__container {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    .cover-image__content {
        z-index: 2;
        margin-top: 64px;
        margin-bottom: 64px;

        .cover-image__title-wrapper {
            margin-bottom: 32px;
        }

        .cover-image__title {
            display: inline;
            position: relative;
            padding-bottom: 12px;

            // This is required this way to only have a border on the last line of the title
            &:after {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 1px;
                // Must be written explicitly to inherit
                border-width: 2PX;
                border-style: solid;
                border-color: inherit;
                content: "";
            }
        }

        h1.cover-image__title {
            font-weight: 700;
            line-height: 1.2;
            letter-spacing: .01em;
            font-size: 56px;

            @media #{map-get($display-breakpoints, 'sm-and-down')} {
                font-size: 48px;
            }

            @media #{map-get($display-breakpoints, 'xs')} {
                font-size: 32px;
            }
        }

        h2.cover-image__title {
            font-weight: 700;
            line-height: 1.2;
            letter-spacing: .01em;
            font-size: 52px;

            @media #{map-get($display-breakpoints, 'sm-and-down')} {
                font-size: 40px !important;
            }

            @media #{map-get($display-breakpoints, 'xs')} {
                font-size: 24px !important;
            }
        }

        .cover-image__subtitle-top {
            font-size: 16px !important;
            text-transform: uppercase;
            font-weight: 700;
            margin-bottom: 0;
            letter-spacing: .075em !important;

            @media #{map-get($display-breakpoints, 'xs')} {
                font-size: 12px !important;
            }
        }

        .cover-image__subtitle-bottom {
            font-size: 18px !important;
            margin-bottom: 0;
            font-weight: 500;
            letter-spacing: .075em !important;

            @media #{map-get($display-breakpoints, 'xs')} {
                font-size: 14px !important;
            }
        }
    }

    .cover-image__bottom-arrow {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 24px;
        padding-top: 16px;
    }
}
</style>