<template>
    <div class="wrapper"
         :class="[props.breakColumn ? 'wrapper-break-' + props.breakColumn : null, {'wrapper-stretch': props.stretch, 'wrapper-grow': props.grow, 'has-break-reverse': props.breakReverse, 'justify-start': props.left, 'justify-center': props.center, 'justify-between': props.between, 'justify-end': !props.left && !props.center && !props.between}]">
        <slot/>
    </div>
</template>

<script setup lang="ts">
const props = defineProps<{
    // The column name on which to break the content
    breakColumn?: 'xs' | 'sm' | 'md' | 'lg' | 'xl',

    // Whether to align the items with 'justify-between'
    between?: boolean,

    // Whether the content shall take the full parent's width up to a max width of 300px for each item
    grow?: boolean,

    // Whether the content shall take the full parent's width without an individual width limit
    stretch?: boolean,

    // Whether to reverse the element order on break
    breakReverse?: boolean,

    // Whether to align the content of the wrapper to the right
    right?: boolean,

    // Whether to align the content of the wrapper to the left
    left?: boolean,

    // Whether to align the content of the wrapper to the center
    center?: boolean,
}>();
</script>

<style lang="scss">
    .wrapper {
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 100%;

        &.wrapper-grow > *:not(span), &.wrapper-stretch > *:not(span) {
            flex-grow: 1;
            margin-bottom: 0 !important;
            margin-top: 0;
        }

        &.wrapper-grow > *:not(span):only-child {
            width: 100%; // IE11 fix (only required on grow)
        }

        &:not(.wrapper-stretch) {
            > *:not(span) {
                @media #{map-get($display-breakpoints, sm-and-up)} {
                    max-width: 300px;
                }
            }
        }

        > *:not(span):not(:first-child):not(:only-child) {
            margin-left: 8px;
        }

        @each $breakpointName, $breakpointMediaKey in ('xs': 'xs', 'sm': 'sm-and-down', 'md': 'md-and-down', 'lg': 'lg-and-down', 'xl': 'xl-and-down') {
            &.wrapper-break-#{$breakpointName} {
                @media #{map-get($display-breakpoints, $breakpointMediaKey)} {
                    flex-direction: column;
                    margin-top: 8px;
                    width: 100%;

                    > *:not(span) {
                        width: 100%;
                    }

                    > *:not(span):not(:first-child):not(:only-child) {
                        margin-top: 8px;
                        margin-left: 0;
                    }

                    &.has-break-reverse {
                        flex-direction: column-reverse;

                        > *:not(span):not(:first-child):not(:only-child) {
                            margin-bottom: 8px !important;
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }

</style>