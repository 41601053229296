<template>
    <NuxtLayout>
        <v-app>
            <app-header/>
            <NuxtPage/>
        </v-app>
    </NuxtLayout>
</template>

<script setup lang="ts">
const route = useRoute();
const config = useRuntimeConfig();

const title = 'Leila Krejci | Musiker • Model • Content Creator';
const description = 'Mein Name ist Leila - ich bin DIY-Artist aus München und liebe alles, was kreativ ist.';

useHead({
    htmlAttrs: {
        lang: 'de'
    },

    title: title,
    titleTemplate: '%s | Leila Krejci',

    meta: [
        {charset: 'utf-8'},
        {name: 'viewport', content: 'width=device-width, initial-scale=1.0, minimum-scale=1.0 maximum-scale=1.0'},
        {name: 'description', content: description},
        {name: 'author', content: 'Leila Krejci'},
        {property: 'og:title', content: title},
        {property: 'og:site_name', content: title},
        {property: 'og:type', content: 'website',},
        {property: 'og:description', content: description,},
        {property: 'og:image', content: '/images/covers/home-cover-desktop.jpg',},
        {property: 'og:url', content: `${config.public.appUrl}${route.path}`},

        // Fix for IE 11 rendering issues
        {'http-equiv': 'X-UA-Compatible', content: 'IE=edge'},

        // Activate iOS PWA Splash Screen
        {name: 'apple-mobile-web-app-capable', content: 'yes'},

        {name: 'msapplication-TileColor', content: '#da532c'},
        {name: 'theme-color', content: '#ffffff'},
    ],
    link: [

        // Ensure query params do not destroy our SEO reputation
        {rel: 'canonical', href: `${config.public.appUrl}${route.path}`},

        // Custom font
        {rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Montserrat:400,500,700'},

        // Favicon
        {rel: 'apple-touch-icon', type: 'image/png', sizes: '180x180', href: '/images/favicons/apple-touch-icon.png'},
        {rel: 'icon', type: 'image/png', sizes: '32x32', href: '/images/favicons/favicon-32x32.png'},
        {rel: 'icon', type: 'image/png', sizes: '16x16', href: '/images/favicons/favicon-16x16.png'},
        {rel: 'manifest', href: '/images/favicons/site.webmanifest'},
        {rel: 'mask-icon', type: 'image/png', color: '#5bbad5', href: '/images/favicons/safari-pinned-tab.svg'},
        {rel: 'shortcut icon', type: 'image/x-icon', href: '/images/favicons/favicon.ico'},
    ]
})
</script>
