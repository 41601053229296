<template>
    <div class="menu-button" :class="{'active': isActive}" @click="isActive = !isActive">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
</template>

<script setup lang="ts">
import {ToggleableComponentEmits, ToggleableComponentProps} from "../composables/useToggleableComponent";

const props = defineProps<{
} & ToggleableComponentProps>();

const emit = defineEmits(ToggleableComponentEmits);

const {isActive} = useToggleableComponent(props, emit);
</script>

<style lang="scss">
@import "../assets/styles/mixins";

$height: 20px;

.menu-button {
    cursor: pointer;
    width: 24px;
    height: $height;
    position: relative;

    @include transform(rotate(0deg));
    @include transition(.5s ease-in-out);

    span {
        display: block;
        position: absolute;
        height: 2.6px;
        width: 100%;
        background: white;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        @include transform(rotate(0deg));
        @include transition(.25s ease-in-out);

        &:nth-child(1) {
            top: 0;
        }

        &:nth-child(2), &:nth-child(3) {
            top: $height * 0.4;
        }

        &:nth-child(4) {
            top: $height * 0.8;
        }
    }

    &.active {
        span:nth-child(1) {
            top: $height * 0.4;
            width: 0;
            left: 50%;
        }

        span:nth-child(2) {
            @include transform(rotate(45deg));
        }

        span:nth-child(3) {
            @include transform(rotate(-45deg));
        }

        span:nth-child(4) {
            top: $height * 0.4;
            width: 0;
            left: 50%;
        }
    }
}
</style>