import type {RouterOptions} from "vue-router";

export default <RouterOptions>{
    scrollBehavior(to, from, savedPosition) {

        if (typeof history !== 'undefined' && history.state.noScroll) {
            return;
        }

        // If a hash is specified, we smoothly scroll to the hash
        if (to.hash) {
            setTimeout(() => {
                const element = document.querySelector(to.hash);
                if (element) {
                    const box = element.getBoundingClientRect();
                    const body = document.body;
                    const docEl = document.documentElement;
                    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
                    const clientTop = docEl.clientTop || body.clientTop || 0;
                    const top = box.top + scrollTop - clientTop;

                    window.scrollTo({
                        top: top,
                        behavior: 'smooth'
                    })
                }
            }, 0); // Wait until the element with the hash is rendered and is being selectable
            return;
        }

        // SavedPosition is only available for popstate navigations (back button) on which we want to restore the scroll position when the user clicked the link
        if (savedPosition) {
            return savedPosition
        }

        // Always scroll to top
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, 50); // Wait until the new page is rendered as scrolling would not be calculated correctly otherwise
        return;
    },
};
